import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import PageHeader from "../components/ui/PageHeader";
import ToolsSelector from "../components/toolbox/ToolSelector";
import { useState } from "react";

const halfLifeTable = {
  Mammalian: {
    Ala: "4.4 hour",
    Arg: "1 hour",
    Asn: "1.4 hour",
    Asp: "1.1 hour",
    Cys: "1.2 hour",
    Gln: "0.8 hour",
    Glu: "1 hour",
    Gly: "30 hour",
    His: "3.5 hour",
    Ile: "20 hour",
    Leu: "5.5 hour",
    Lys: "1.3 hour",
    Met: "30 hour",
    Phe: "1.1 hour",
    Pro: ">20 hour",
    Ser: "1.9 hour",
    Thr: "7.2 hour",
    Trp: "2.8 hour",
    Tyr: "2.8 hour",
    Val: "100 hour",
  },
  Yeast: {
    Ala: ">20 hour",
    Arg: "2 min",
    Asn: "3 min",
    Asp: "3 min",
    Cys: ">20 hour",
    Gln: "10 min",
    Glu: "30 min",
    Gly: ">20 hour",
    His: "10 min",
    Ile: "30 min",
    Leu: "3 min",
    Lys: "3 min",
    Met: ">20 hour",
    Phe: "3 min",
    Pro: ">20 hour",
    Ser: ">20 hour",
    Thr: ">20 hour",
    Trp: "3 min",
    Tyr: "10 min",
    Val: ">20 hour",
  },
  "E. coli": {
    Ala: ">10 hour",
    Arg: "2 min",
    Asn: ">10 hour",
    Asp: ">10 hour",
    Cys: ">10 hour",
    Gln: ">10 hour",
    Glu: ">10 hour",
    Gly: ">10 hour",
    His: ">10 hour",
    Ile: ">10 hour",
    Leu: "2 min",
    Lys: "2 min",
    Met: ">10 hour",
    Phe: "2 min",
    Pro: "?",
    Ser: ">10 hour",
    Thr: ">10 hour",
    Trp: "2 min",
    Tyr: "2 min",
    Val: ">10 hour",
  },
};

function estimateHalfLife(sequence, organism) {
  if (!sequence || sequence.length === 0) {
    return "Invalid protein sequence.";
  }
  const lastAminoAcid = sequence.slice(-1).toUpperCase();
  const aminoAcidMap = {
    A: "Ala",
    R: "Arg",
    N: "Asn",
    D: "Asp",
    C: "Cys",
    Q: "Gln",
    E: "Glu",
    G: "Gly",
    H: "His",
    I: "Ile",
    L: "Leu",
    K: "Lys",
    M: "Met",
    F: "Phe",
    P: "Pro",
    S: "Ser",
    T: "Thr",
    W: "Trp",
    Y: "Tyr",
    V: "Val",
  };
  const aminoAcidName = aminoAcidMap[lastAminoAcid];
  if (!aminoAcidName) {
    return `Invalid amino acid code: ${lastAminoAcid}`;
  }
  if (organism === "All") {
    return {
      aminoAcid: aminoAcidName,
      halfLives: Object.keys(halfLifeTable).map((org) => ({
        organism: org,
        halfLife: halfLifeTable[org][aminoAcidName] || "No data available",
      })),
    };
  }
  if (!halfLifeTable[organism]) {
    return `Organism ${organism} not supported.`;
  }
  return {
    aminoAcid: aminoAcidName,
    halfLives: [
      {
        organism,
        halfLife: halfLifeTable[organism][aminoAcidName] || "No data available",
      },
    ],
  };
}

export default function HalfLifeEstimation() {
  const [sequence, setSequence] = useState("");
  const [organism, setOrganism] = useState("All");
  const [result, setResult] = useState(null);

  const handleSequenceChange = (e) => {
    setResult(null);
    setSequence(e.target.value);
  };
  const handleOrganismChange = (e) => {
    setResult(null);
    setOrganism(e.target.value);
  };

  const calculateHalfLife = () => {
    // Validate sequence before calculation
    if (!/^[ACDEFGHIKLMNPQRSTVWY]+$/i.test(sequence)) {
      setResult({ error: true, message: "Invalid protein sequence." });
      return;
    }
    setResult(estimateHalfLife(sequence, organism));
  };

  return (
    <MainLayout>
      <PageHeader to="/tools" title="Half-life Estimation">
        <ToolsSelector id={14} />
      </PageHeader>
      <ContentLayout>
        <section className="space-y-2">
          <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            1. Insert your FASTA Sequence
          </label>
          <textarea
            value={sequence}
            onChange={handleSequenceChange}
            rows="5"
            className={`w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 ${
              result?.error ? "border-2 border-red-500" : ""
            }`}
            placeholder=">Sequence"
          />
        </section>
        <section className="space-y-2">
          <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            2. Select Organism
          </label>
          <select
            value={organism}
            onChange={handleOrganismChange}
            className="w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none focus:!border-blue-500 dark:focus:!border-blue-400 duration-200">
            <option value="All">All</option>
            <option value="Mammalian">Mammalian</option>
            <option value="Yeast">Yeast</option>
            <option value="E. coli">E. coli</option>
          </select>
        </section>
        <button
          onClick={calculateHalfLife}
          className="border-light dark:border-dark bg-primary-btn-light enabled:hover:bg-primary-btn-hover dark:bg-primary-btn-dark enabled:dark:hover:bg-primary-btn-hoverDark text-primary-dark disabled:cursor-not-allowed disabled:bg-opacity-50 w-full px-4 py-2 pr-6 mb-4 text-lg font-semibold text-center duration-200 rounded-lg">
          Calculate Half-life
        </button>
        {result && (
          <section className="mt-4 space-y-2">
            <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
              Estimated Half-life
            </label>
            {result.error ? (
              <div className="bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark p-4 border-2 border-red-500 rounded-lg shadow-lg">
                <h3 className="text-lg font-semibold text-center">Error</h3>
                <p className="text-center">{result.message}</p>
              </div>
            ) : (
              <div className="md:grid-cols-4 grid grid-cols-1 gap-4">
                <div className="bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark p-4 rounded-lg shadow-lg">
                  <h3 className="text-lg font-semibold text-center">
                    Last Amino Acid
                  </h3>
                  <p className="text-center">{result.aminoAcid}</p>
                </div>
                {result.halfLives.map(({ organism, halfLife }, index) => (
                  <div
                    key={index}
                    className="bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark p-4 rounded-lg shadow-lg">
                    <h3 className="text-lg font-semibold text-center">
                      {organism}
                    </h3>
                    <p className="text-center">{halfLife}</p>
                  </div>
                ))}
              </div>
            )}
          </section>
        )}
      </ContentLayout>
    </MainLayout>
  );
}
