import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import PageHeader from "../components/ui/PageHeader";
import ToolsSelector from "../components/toolbox/ToolSelector";
import { useState } from "react";
import { validateFastaSequence } from "../helpers/validation";

export default function ConcatenateSequences() {
  const [sequenceType, setSequenceType] = useState("");
  const [sequences, setSequences] = useState("");
  const [result, setResult] = useState(null);
  const [sequenceError, setSequenceError] = useState(null);
  const [sequenceTypeError, setSequenceTypeError] = useState(null);

  const handleSequenceTypeChange = (e) => {
    setSequenceType(e.target.value);
    setSequenceTypeError(null);
    setSequenceError(null);
    setResult(null);
  };

  const handleSequencesChange = (e) => {
    setSequences(e.target.value);
    setSequenceError(null);
    setSequenceTypeError(null);
    setResult(null);
  };

  const handleConcatenate = () => {
    if (!sequenceType) {
      setSequenceTypeError(
        "Please select a sequence type (Nucleotide or Amino Acids)."
      );
      return;
    }

    const cleanedSequences = sequences.toUpperCase().replace(/[^A-Z,]/g, "");

    const seqArray = cleanedSequences
      .split(",")
      .map((seq) => seq.trim())
      .filter((seq) => seq);

    if (seqArray.length < 2) {
      setSequenceError(
        "Please enter at least 2 valid sequences separated by commas."
      );
      return;
    }

    for (let seq of seqArray) {
      if (!validateFastaSequence(seq, sequenceType.toLowerCase())) {
        setSequenceError(`Invalid sequence detected: ${seq}`);
        return;
      }
    }

    const concatenatedSequence = seqArray.join("");
    setResult(concatenatedSequence);
  };

  return (
    <MainLayout>
      <PageHeader to="/tools" title="Concatenate Sequences">
        <ToolsSelector id={18} />
      </PageHeader>
      <ContentLayout>
        <section className="space-y-2">
          <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            1. Select Sequence Type
          </label>
          <select
            value={sequenceType}
            onChange={handleSequenceTypeChange}
            className={`w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 ${
              sequenceTypeError ? "border-2 !border-red-500" : ""
            }`}>
            <option value="">Select type</option>
            <option value="dna">Nucleotide</option>
            <option value="protein">Amino Acids</option>
          </select>
          {sequenceTypeError && (
            <p className="mt-2 text-sm text-red-500">{sequenceTypeError}</p>
          )}
        </section>

        <section className="mt-4 space-y-2">
          <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            2. Insert your sequences (separated by commas)
          </label>
          <textarea
            value={sequences}
            onChange={handleSequencesChange}
            rows="5"
            className={`w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 ${
              sequenceError ? "border-2 !border-red-500" : ""
            }`}
            placeholder="e.g. ATGC,GCTA,TACG"
          />
          {sequenceError && (
            <p className="mt-2 text-sm text-red-500">{sequenceError}</p>
          )}
        </section>

        <button
          onClick={handleConcatenate}
          className="border-light dark:border-dark bg-primary-btn-light enabled:hover:bg-primary-btn-hover dark:bg-primary-btn-dark enabled:dark:hover:bg-primary-btn-hoverDark text-primary-dark w-full px-4 py-2 pr-6 mb-4 text-lg font-semibold text-center duration-200 rounded-lg">
          Concatenate Sequences
        </button>

        {result && !sequenceError && !sequenceTypeError && (
          <section className="mt-4 space-y-2">
            <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
              Concatenated Sequence
            </label>
            <textarea
              readOnly
              value={result}
              rows="5"
              className="w-full p-2 rounded-lg bg-white dark:bg-gray-800 text-primary-light dark:text-primary-dark border-light dark:border-dark outline-none focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200"
            />
          </section>
        )}
      </ContentLayout>
    </MainLayout>
  );
}
