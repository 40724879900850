import PropTypes from "prop-types";
import ToolBoxHeader from "./ToolBoxHeader";
import tools from "../../data/tools.json";

const labelsSet = new Set(tools.flatMap((tool) => tool.labels));
const labelsArray = Array.from(labelsSet);

export default function ToolLabels({
  setShownTools,
  selectedLabels,
  setSelectedLabels,
}) {
  const handleLabelClick = (label) => {
    const isSelected = selectedLabels.includes(label);
    const newLabels = isSelected
      ? selectedLabels.filter((l) => l !== label)
      : [...selectedLabels, label];

    const filteredTools = tools.filter((tool) =>
      newLabels.every((selectedLabel) => tool.labels.includes(selectedLabel))
    );

    setSelectedLabels(newLabels);
    setShownTools(filteredTools);
  };

  return (
    <section className=" flex flex-col gap-4">
      <ToolBoxHeader imageName="label" title="Labels" />
      <div className="md:grid-cols-3 border-light dark:border-dark dark:bg-secondary-dark bg-secondary-light rounded-xl shadow-light dark:shadow-dark grid grid-cols-2 gap-3 px-4 py-6 shadow-md">
        {labelsArray.map((label) => (
          <button
            key={label}
            onClick={() => handleLabelClick(label)}
            className={`px-4 py-2 font-medium dark:text-white rounded-lg transition-all duration-200 ${
              selectedLabels.includes(label)
                ? "bg-primary-btn-light dark:bg-primary-btn-dark hover:bg-primary-btn-hover dark:hover:bg-primary-btn-hoverDark !text-white"
                : "bg-tertiary-light dark:bg-tertiary-dark hover:bg-tertiary-light/70 dark:hover:bg-tertiary-dark/70"
            }`}>
            {label}
          </button>
        ))}
      </div>
    </section>
  );
}

ToolLabels.propTypes = {
  setShownTools: PropTypes.func.isRequired,
  selectedLabels: PropTypes.array.isRequired,
  setSelectedLabels: PropTypes.func.isRequired,
};
