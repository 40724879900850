import AliphaticIndexCalculator from "../pages/AliphaticIndex";
import AminoacidCounter from "../pages/AminoacidCounter";
import AromaticityScore from "../pages/AromaticityScore";
import ConcatSequences from "../pages/ConcatSequences";
import DNAToRNA from "../pages/DNAToRNA";
import FastaConverter from "../pages/FastaConverter";
import GRASOrganism from "../pages/GRASOrganism";
import HalfLifeEstimation from "../pages/HalfLifeEstimation";
import Home from "../pages/Home";
import InstabilityIndex from "../pages/InstabilityIndex";
import IsoelectricPoint from "../pages/IsoelectricPoint";
import MolecularExtinction from "../pages/MolecularExinction";
import NSpacers from "../pages/NSpacers";
import NucleotideCounter from "../pages/NucleotideCounter";
import NucleotideToAminoacid from "../pages/NucleotideToAminoacid";
import PolarityIndex from "../pages/PolarityIndex";
import Projects from "../pages/Projects";
import ReverseComplement from "../pages/ReverseComplement";
import SequenceCutter from "../pages/SequenceCutter";
import ToolBox from "../pages/ToolBox";
import { Route, Routes } from "react-router-dom";

export default function LoginRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Home />} />
      <Route path="/projects" element={<Projects />} />

      {/* Tools */}
      <Route path="/tools" element={<ToolBox />} />
      <Route path="/tools/sequence_cutter" element={<SequenceCutter />} />
      <Route path="/tools/nucleotide_counter" element={<NucleotideCounter />} />
      <Route path="/tools/aminoacid_counter" element={<AminoacidCounter />} />
      <Route path="/tools/fasta_converter" element={<FastaConverter />} />
      <Route path="/tools/gras_organism_list" element={<GRASOrganism />} />
      <Route
        path="/tools/aliphatic_index"
        element={<AliphaticIndexCalculator />}
      />
      <Route path="/tools/aromaticity_score" element={<AromaticityScore />} />
      <Route
        path="/tools/nucleotide_translator"
        element={<NucleotideToAminoacid />}
      />
      <Route path="/tools/instability_index" element={<InstabilityIndex />} />
      <Route path="/tools/isoelectric_point" element={<IsoelectricPoint />} />
      <Route
        path="/tools/half_life_estimation"
        element={<HalfLifeEstimation />}
      />
      <Route
        path="/tools/molecular_extinction"
        element={<MolecularExtinction />}
      />
      <Route path="/tools/polarity_index" element={<PolarityIndex />} />
      <Route path="/tools/n_spacers" element={<NSpacers />} />
      <Route path="/tools/concat_sequences" element={<ConcatSequences />} />
      <Route path="/tools/reverse_complement" element={<ReverseComplement />} />
      <Route path="/tools/dna_to_rna" element={<DNAToRNA />} />
    </Routes>
  );
}
