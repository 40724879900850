import PropTypes from "prop-types";
import ToolBoxHeader from "./ToolBoxHeader";
import tools from "../../data/tools.json";

export default function ToolSearchBar({
  setShownTools,
  search,
  setSearch,
  selectedLabels,
  shownTools,
}) {
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearch(value);

    // Filtrado inicial por etiquetas seleccionadas
    const toolsByLabels = selectedLabels.length
      ? tools.filter((tool) =>
          selectedLabels.every((label) => tool.labels.includes(label))
        )
      : tools;

    // Si el campo de búsqueda está vacío, mostrar herramientas filtradas por etiquetas
    if (value.trim() === "") {
      setShownTools(toolsByLabels);
      return;
    }

    // Filtrado por búsqueda de texto sobre las herramientas filtradas por etiquetas
    const lowercasedValue = value.toLowerCase();
    const filteredTools = toolsByLabels.filter(
      (tool) =>
        tool.name.toLowerCase().includes(lowercasedValue) ||
        tool.description.toLowerCase().includes(lowercasedValue)
    );

    setShownTools(filteredTools);
  };

  return (
    <section className="flex flex-col gap-4">
      <ToolBoxHeader imageName="search" title="Search" />
      <div className="md:grid-cols-3 border-light dark:border-dark dark:bg-secondary-dark bg-secondary-light rounded-xl shadow-light dark:shadow-dark flex flex-col gap-3 px-4 pt-6 pb-4 shadow-md">
        <input
          type="text"
          name="search"
          id="search"
          className="rounded-lg w-full p-2 bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border outline-none placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark border-light dark:border-dark dark:focus:!border-blue-400 focus:!border-blue-500"
          placeholder="Aromaticity Score"
          value={search}
          onChange={handleSearch}
        />
        <p className="text-secondary-light dark:text-secondary-dark font-medium">
          Tools found with search criteria: {shownTools.length}
        </p>
      </div>
    </section>
  );
}

ToolSearchBar.propTypes = {
  setShownTools: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  selectedLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};
