import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import PageHeader from "../components/ui/PageHeader";
import ToolsSelector from "../components/toolbox/ToolSelector";
import diwcMatrix from "../data/diwc_matrix.json";
import { useState } from "react";

function calculateInstabilityIndex(proteinSequence) {
  let totalDIWV = 0;
  let L = proteinSequence.length;

  for (let i = 0; i < L - 1; i++) {
    let dipeptide = proteinSequence.slice(i, i + 2);
    totalDIWV += diwcMatrix[dipeptide] || 0;
  }

  let instabilityIndex = (10 / L) * totalDIWV;
  let predictedStability = instabilityIndex < 40 ? "Stable" : "Unstable";

  return { instabilityIndex, predictedStability };
}

export default function InstabilityIndex() {
  const [sequence, setSequence] = useState("");
  const [result, setResult] = useState({
    instabilityIndex: null,
    predictedStability: null,
  });

  const handleSequenceChange = (e) => {
    const input = e.target.value
      .toUpperCase()
      .replace(/[^ACDEFGHIKLMNPQRSTVWY]/g, "");
    setSequence(input);

    if (input.length > 1) {
      const calculation = calculateInstabilityIndex(input);
      setResult(calculation);
    } else {
      setResult({ instabilityIndex: null, predictedStability: null });
    }
  };

  return (
    <MainLayout>
      <PageHeader to="/tools" title="Instability Index">
        <ToolsSelector id={12} />
      </PageHeader>
      <ContentLayout>
        <section className="space-y-2">
          <label
            htmlFor="fasta"
            className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            1. Insert your FASTA Sequence
          </label>
          <textarea
            value={sequence}
            onChange={handleSequenceChange}
            name="fasta"
            id="fasta"
            cols="30"
            rows="10"
            className="w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark"
            placeholder=">Sequence"
          />
        </section>
        <section className="space-y-2">
          <label
            htmlFor="instability"
            className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            Instability Index Results
          </label>
          <div className="grid grid-cols-1 gap-4">
            <input
              className="text-center !border-light dark:!border-dark w-full p-2 rounded-lg bg-white dark:!bg-gray-800 text-primary-light dark:text-primary-dark border outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-default disabled:!bg-gray-200 dark:disabled:!bg-gray-700"
              readOnly
              disabled
              name="instabilityIndex"
              id="instabilityIndex"
              placeholder="Instability Index"
              value={
                result.instabilityIndex !== null
                  ? result.instabilityIndex.toFixed(2)
                  : ""
              }
            />
            <input
              className="text-center !border-light dark:!border-dark w-full p-2 rounded-lg bg-white dark:!bg-gray-800 text-primary-light dark:text-primary-dark border outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-default disabled:!bg-gray-200 dark:disabled:!bg-gray-700"
              readOnly
              disabled
              name="predictedStability"
              id="predictedStability"
              placeholder="Predicted Stability"
              value={result.predictedStability || ""}
            />
          </div>
        </section>
      </ContentLayout>
    </MainLayout>
  );
}
