import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import PageHeader from "../components/ui/PageHeader";
import ToolsSelector from "../components/toolbox/ToolSelector";
import { useState } from "react";

function countAminoAcids(sequence) {
  const counts = {};
  for (const aa of sequence) {
    counts[aa] = (counts[aa] || 0) + 1;
  }
  return counts;
}

function calculateMolecularWeight(sequence) {
  const weightsAA = {
    A: 89.0,
    C: 121.0,
    D: 133.0,
    E: 147.0,
    F: 165.0,
    G: 75.0,
    H: 155.0,
    I: 131.0,
    K: 146.0,
    L: 131.0,
    M: 149.0,
    N: 132.0,
    P: 115.0,
    Q: 146.0,
    R: 174.0,
    S: 105.0,
    T: 119.0,
    V: 117.0,
    W: 204.0,
    Y: 181.0,
  };
  return sequence
    .split("")
    .reduce((total, aa) => total + (weightsAA[aa] || 0), 0);
}

function calculateMolarExtinctionCoefficientAndAbsorbance(proteinSequence) {
  const numAA = countAminoAcids(proteinSequence);
  const numW = numAA["W"] || 0;
  const numY = numAA["Y"] || 0;
  const numC = numAA["C"] || 0;
  const mecReduced = numW * 5500 + numY * 1490;
  const mecCystines = mecReduced + Math.floor(numC / 2) * 125;
  const molecularWeight = calculateMolecularWeight(proteinSequence);
  const absorbReduced = mecReduced / molecularWeight;
  const absorbCystines = mecCystines / molecularWeight;
  return {
    mecReduced,
    mecCystines,
    molecularWeight: molecularWeight.toFixed(2),
    absorbReduced: absorbReduced.toFixed(4),
    absorbCystines: absorbCystines.toFixed(4),
  };
}

export default function MolecularExtinction() {
  const [sequence, setSequence] = useState("");
  const [result, setResult] = useState(null);

  const handleSequenceChange = (e) => {
    setResult(null);
    setSequence(e.target.value);
  };

  const calculateExtinction = () => {
    // Check for invalid sequence
    if (!sequence || !/^[ACDEFGHIKLMNPQRSTVWY]+$/i.test(sequence)) {
      setResult({ error: true, message: "Invalid protein sequence." });
      return;
    }
    // Calculate extinction and absorbance
    setResult(calculateMolarExtinctionCoefficientAndAbsorbance(sequence));
  };

  return (
    <MainLayout>
      <PageHeader
        to="/tools"
        title="Molecular Extinction Coefficient and Absorbance">
        <ToolsSelector id={15} />
      </PageHeader>
      <ContentLayout>
        <section className="space-y-2">
          <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            1. Insert your FASTA Sequence
          </label>
          <textarea
            value={sequence}
            onChange={handleSequenceChange}
            rows="5"
            className="w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200"
            placeholder=">Sequence"
          />
        </section>
        <button
          onClick={calculateExtinction}
          className="border-light dark:border-dark bg-primary-btn-light enabled:hover:bg-primary-btn-hover dark:bg-primary-btn-dark enabled:dark:hover:bg-primary-btn-hoverDark text-primary-dark disabled:cursor-not-allowed disabled:bg-opacity-50 w-full px-4 py-2 pr-6 mb-4 text-lg font-semibold text-center duration-200 rounded-lg">
          Calculate Extinction
        </button>
        {result && (
          <section className="mt-4 space-y-2">
            <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
              Results
            </label>
            {result.error ? (
              <div className="bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark p-4 border-2 border-red-500 rounded-lg shadow-lg">
                <h3 className="text-lg font-semibold text-center">Error</h3>
                <p className="text-center">{result.message}</p>
              </div>
            ) : (
              <div className="md:grid-cols-3 grid grid-cols-1 gap-4">
                <div className="bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark p-4 rounded-lg shadow-lg">
                  <h3 className="text-lg font-semibold text-center">
                    Molar Extinction (Reduced Cysteines)
                  </h3>
                  <p className="text-center">{result.mecReduced}</p>
                </div>
                <div className="bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark p-4 rounded-lg shadow-lg">
                  <h3 className="text-lg font-semibold text-center">
                    Molar Extinction (Cystines)
                  </h3>
                  <p className="text-center">{result.mecCystines}</p>
                </div>
                <div className="bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark p-4 rounded-lg shadow-lg">
                  <h3 className="text-lg font-semibold text-center">
                    Molecular Weight
                  </h3>
                  <p className="text-center">{result.molecularWeight} Da</p>
                </div>
                <div className="bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark p-4 rounded-lg shadow-lg">
                  <h3 className="text-lg font-semibold text-center">
                    Absorbance (Reduced Cysteines)
                  </h3>
                  <p className="text-center">{result.absorbReduced}</p>
                </div>
                <div className="bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark p-4 rounded-lg shadow-lg">
                  <h3 className="text-lg font-semibold text-center">
                    Absorbance (Cystines)
                  </h3>
                  <p className="text-center">{result.absorbCystines}</p>
                </div>
              </div>
            )}
          </section>
        )}
      </ContentLayout>
    </MainLayout>
  );
}
