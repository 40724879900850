import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import PageHeader from "../components/ui/PageHeader";
import ToolsSelector from "../components/toolbox/ToolSelector";
import { useState } from "react";

// pKa values for ionizable groups based on the Bjellqvist method
const positivePKs = { Nterm: 7.5, K: 10.0, R: 12.0, H: 5.98 };
const negativePKs = { Cterm: 3.55, D: 4.05, E: 4.45, C: 9.0, Y: 10.0 };
const pKaNterm = {
  A: 7.59,
  M: 7.0,
  S: 6.93,
  P: 8.36,
  T: 6.82,
  V: 7.44,
  E: 7.7,
};
const pKaCterm = { D: 4.55, E: 4.75 };

// Function to count occurrences of charged amino acids
function countChargedAAs(sequence) {
  const counts = {
    Nterm: 1.0,
    Cterm: 1.0,
    K: 0,
    R: 0,
    H: 0,
    D: 0,
    E: 0,
    C: 0,
    Y: 0,
  };
  for (let aa of sequence.toUpperCase()) {
    if (counts.hasOwnProperty(aa)) {
      counts[aa] += 1;
    }
  }
  return counts;
}

// Function to adjust terminal pKa values based on sequence
function adjustTerminalPKs(sequence) {
  const posPKs = { ...positivePKs };
  const negPKs = { ...negativePKs };
  const nterm = sequence[0];
  const cterm = sequence[sequence.length - 1];
  if (pKaNterm.hasOwnProperty(nterm)) {
    posPKs.Nterm = pKaNterm[nterm];
  }
  if (pKaCterm.hasOwnProperty(cterm)) {
    negPKs.Cterm = pKaCterm[cterm];
  }
  return { posPKs, negPKs };
}

// Function to calculate the charge of the protein at a given pH
function calculateChargeAtPH(sequence, pH, posPKs, negPKs) {
  const counts = countChargedAAs(sequence);
  let positiveCharge = 0.0;
  for (let [aa, pKa] of Object.entries(posPKs)) {
    const partialCharge = 1.0 / (1.0 + Math.pow(10, pH - pKa));
    positiveCharge += counts[aa] * partialCharge;
  }
  let negativeCharge = 0.0;
  for (let [aa, pKa] of Object.entries(negPKs)) {
    const partialCharge = 1.0 / (1.0 + Math.pow(10, pKa - pH));
    negativeCharge += counts[aa] * partialCharge;
  }
  return positiveCharge - negativeCharge;
}

// Recursive function to calculate the isoelectric point (pI)
function calculatePI(sequence, pH = 7.0, minPH = 3.0, maxPH = 12.0) {
  if (!sequence || sequence.length === 0) {
    return "Invalid protein sequence.";
  }
  const { posPKs, negPKs } = adjustTerminalPKs(sequence);
  const charge = calculateChargeAtPH(sequence, pH, posPKs, negPKs);
  if (maxPH - minPH > 0.0001) {
    if (charge > 0) {
      minPH = pH;
    } else {
      maxPH = pH;
    }
    const nextPH = (minPH + maxPH) / 2;
    return calculatePI(sequence, nextPH, minPH, maxPH);
  }
  return pH.toFixed(2);
}

export default function IsoelectricPoint() {
  const [sequence, setSequence] = useState("");
  const [result, setResult] = useState(null);

  const handleSequenceChange = (e) => {
    setResult(null);
    setSequence(e.target.value);
  };

  const calculateIsoelectricPoint = () => {
    // Validate sequence before calculation
    if (!/^[ACDEFGHIKLMNPQRSTVWY]+$/i.test(sequence)) {
      setResult({ error: true, message: "Invalid protein sequence." });
      return;
    }
    const pI = calculatePI(sequence);
    setResult(pI);
  };

  return (
    <MainLayout>
      <PageHeader to="/tools" title="Isoelectric Point">
        <ToolsSelector id={13} />
      </PageHeader>
      <ContentLayout>
        <section className="space-y-2">
          <label
            htmlFor="fasta"
            className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            1. Insert your FASTA Sequence
          </label>
          <textarea
            value={sequence}
            onChange={handleSequenceChange}
            id="fasta"
            rows="5"
            className={`w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 ${
              result?.error ? "border-2 border-red-500" : ""
            }`}
            placeholder=">Sequence"
          />
        </section>
        <button
          onClick={calculateIsoelectricPoint}
          className="border-light dark:border-dark bg-primary-btn-light enabled:hover:bg-primary-btn-hover dark:bg-primary-btn-dark enabled:dark:hover:bg-primary-btn-hoverDark text-primary-dark disabled:cursor-not-allowed disabled:bg-opacity-50 w-full px-4 py-2 pr-6 mb-4 text-lg font-semibold text-center duration-200 rounded-lg">
          Calculate pI
        </button>
        {result && (
          <section className="mt-4 space-y-2">
            <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
              Isoelectric Point
            </label>
            {result.error ? (
              <div className="bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark p-4 border-2 border-red-500 rounded-lg shadow-lg">
                <h3 className="text-lg font-semibold text-center">Error</h3>
                <p className="text-center">{result.message}</p>
              </div>
            ) : (
              <input
                className="text-center w-full p-2 rounded-lg bg-white dark:!bg-gray-800 text-primary-light dark:text-primary-dark border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 border-2"
                readOnly
                value={result}
              />
            )}
          </section>
        )}
      </ContentLayout>
    </MainLayout>
  );
}
