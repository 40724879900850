import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import PageHeader from "../components/ui/PageHeader";
import ToolsSelector from "../components/toolbox/ToolSelector";
import { useState } from "react";

// Function to calculate Polarity index
function calculatePolarityIndex(sequence) {
  // Verify sequence exists
  if (!sequence || sequence.length === 0) {
    return "Invalid protein sequence.";
  }
  const polarity = {
    A: 8.1,
    C: 5.5,
    D: 13.0,
    E: 12.3,
    F: 5.2,
    G: 9.0,
    H: 10.4,
    I: 5.2,
    K: 11.3,
    L: 4.9,
    M: 5.7,
    N: 13.0,
    P: 8.0,
    Q: 12.3,
    R: 10.5,
    S: 9.2,
    T: 8.6,
    V: 5.9,
    W: 5.4,
    Y: 6.2,
  };
  let totalPolarity = 0;
  for (let aa of sequence) {
    totalPolarity += polarity[aa] || 0;
  }
  return (totalPolarity / sequence.length).toFixed(2);
}

export default function PolarityIndex() {
  const [sequence, setSequence] = useState("");
  const [result, setResult] = useState(null);

  const handleSequenceChange = (e) => {
    setResult(null);
    setSequence(e.target.value);
  };

  const calculateAndDisplayPolarity = () => {
    const polarityIndex = calculatePolarityIndex(sequence);
    if (polarityIndex === "Invalid protein sequence.") {
      setResult({ error: true, message: polarityIndex });
    } else {
      setResult({ polarityIndex });
    }
  };

  return (
    <MainLayout>
      <PageHeader to="/tools" title="Amino Acid Polarity Index Tool">
        <ToolsSelector id={16} />
      </PageHeader>
      <ContentLayout>
        <section className="space-y-2">
          <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            1. Insert your FASTA Sequence
          </label>
          <textarea
            value={sequence}
            onChange={handleSequenceChange}
            rows="5"
            className="w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200"
            placeholder=">Sequence"
          />
        </section>
        <button
          onClick={calculateAndDisplayPolarity}
          className="border-light dark:border-dark bg-primary-btn-light enabled:hover:bg-primary-btn-hover dark:bg-primary-btn-dark enabled:dark:hover:bg-primary-btn-hoverDark text-primary-dark disabled:cursor-not-allowed disabled:bg-opacity-50 w-full px-4 py-2 pr-6 mb-4 text-lg font-semibold text-center duration-200 rounded-lg">
          Calculate Polarity Index
        </button>
        {result && (
          <section className="mt-4 space-y-2">
            <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
              Results
            </label>
            <div className="md:grid-cols-3 grid grid-cols-1 gap-4">
              <div
                className={`bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark p-4 rounded-lg shadow-lg ${
                  result.error ? "border-2 border-red-500" : ""
                }`}>
                <h3 className="text-lg font-semibold text-center">
                  {result.error ? "Error" : "Polarity Index"}
                </h3>
                <p className="text-center">
                  {result.error ? result.message : result.polarityIndex}
                </p>
              </div>
            </div>
          </section>
        )}
      </ContentLayout>
    </MainLayout>
  );
}
