import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import PageHeader from "../components/ui/PageHeader";
import ToolsSelector from "../components/toolbox/ToolSelector";
import { useState } from "react";

function reverseComplement(dna) {
  const complement = { A: "T", T: "A", C: "G", G: "C" };
  return dna
    .split("")
    .map((base) => complement[base] || base)
    .reverse()
    .join("");
}

function basicTranscription(dna) {
  return dna.replace(/T/g, "U");
}

function transcriptionFromTemplate(templateDNA) {
  const codingStrand = reverseComplement(templateDNA);
  return basicTranscription(codingStrand);
}

function backTranscription(rna) {
  return rna.replace(/U/g, "T");
}

export default function DNAToRNA() {
  const [sequence, setSequence] = useState("");
  const [mode, setMode] = useState("basic");
  const [result, setResult] = useState(null);
  const [sequenceError, setSequenceError] = useState(null);

  const handleSequenceChange = (e) => {
    setSequence(e.target.value);
    setSequenceError(null);
    setResult(null);
  };

  const handleModeChange = (e) => {
    setMode(e.target.value);
    setSequenceError(null);
    setResult(null);
  };

  const handleTranscription = () => {
    const trimmedSequence = sequence.trim();

    if (!trimmedSequence) {
      setSequenceError("Please enter a valid nucleotide sequence.");
      return;
    }

    let isValidSequence = false;
    switch (mode) {
      case "basic":
      case "reverseComplement":
        isValidSequence = /^[ATCG]+$/i.test(trimmedSequence);
        if (!isValidSequence) {
          setSequenceError(
            "Invalid DNA sequence. Only A, T, C, G are allowed."
          );
          return;
        }
        break;
      case "backTranscribe":
        isValidSequence = /^[AUGC]+$/i.test(trimmedSequence);
        if (!isValidSequence) {
          setSequenceError(
            "Invalid RNA sequence. Only A, U, G, C are allowed."
          );
          return;
        }
        break;
      default:
        setSequenceError("Invalid transcription mode selected.");
        return;
    }

    let transcriptionResult;
    switch (mode) {
      case "basic":
        transcriptionResult = basicTranscription(trimmedSequence);
        break;
      case "reverseComplement":
        transcriptionResult = transcriptionFromTemplate(trimmedSequence);
        break;
      case "backTranscribe":
        transcriptionResult = backTranscription(trimmedSequence);
        break;
      default:
        transcriptionResult = "Invalid mode";
    }

    setResult(transcriptionResult);
  };

  return (
    <MainLayout>
      <PageHeader to="/tools" title="DNA to RNA Transcription">
        <ToolsSelector id={20} />
      </PageHeader>
      <ContentLayout>
        <section className="space-y-2">
          <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            1. Select Transcription Mode
          </label>
          <select
            value={mode}
            onChange={handleModeChange}
            className={`w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200`}>
            <option value="basic">Basic Transcription (DNA → RNA)</option>
            <option value="reverseComplement">
              Reverse Complement (DNA → RNA)
            </option>
            <option value="backTranscribe">
              Back Transcription (RNA → DNA)
            </option>
          </select>
        </section>

        <section className="mt-4 space-y-2">
          <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            2. Enter your Sequence
          </label>
          <textarea
            value={sequence}
            onChange={handleSequenceChange}
            rows="5"
            className={`w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 ${
              sequenceError ? "border-2 !border-red-500" : ""
            }`}
            placeholder="e.g. GATTACA or GAUUACA"
          />
          {sequenceError && (
            <p className="mt-2 text-sm text-red-500">{sequenceError}</p>
          )}
        </section>

        <button
          onClick={handleTranscription}
          className="border-light dark:border-dark bg-primary-btn-light enabled:hover:bg-primary-btn-hover dark:bg-primary-btn-dark enabled:dark:hover:bg-primary-btn-hoverDark text-primary-dark w-full px-4 py-2 pr-6 mb-4 text-lg font-semibold text-center duration-200 rounded-lg">
          Transcribe Sequence
        </button>

        {result && !sequenceError && (
          <section className="mt-4 space-y-2">
            <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
              Resulting Sequence
            </label>
            <textarea
              readOnly
              value={result}
              rows="5"
              className="w-full p-2 rounded-lg bg-white dark:bg-gray-800 text-primary-light dark:text-primary-dark border-light dark:border-dark outline-none focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200"
            />
          </section>
        )}
      </ContentLayout>
    </MainLayout>
  );
}
