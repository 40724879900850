import PropTypes from "prop-types";
import { useContext } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { getImageUrl } from "../../utils/helpers";

export default function ToolBoxHeader({ imageName = "", title = "" }) {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;
  return (
    <div className="flex items-center gap-3">
      <img
        src={getImageUrl(`${imageName}.svg`, isDark)}
        alt={`${imageName} icon`}
        className="w-8 h-8"
      />
      <span className="text-secondary-light dark:text-secondary-dark text-xl font-semibold">
        {title}
      </span>
    </div>
  );
}

ToolBoxHeader.propTypes = {
  imageName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
