import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import PageTitle from "../components/ui/PageTitle";
import Tool from "../components/toolbox/Tool";
import ToolLabels from "../components/toolbox/ToolLabels";
import ToolSearchBar from "../components/toolbox/ToolSearchBar";
import Tools from "../components/toolbox/Tools";
import tools from "../data/tools.json";
import { useState } from "react";

export default function ToolBox() {
  const [shownTools, setShownTools] = useState(tools);
  const [search, setSearch] = useState("");
  const [selectedLabels, setSelectedLabels] = useState([]);

  return (
    <MainLayout>
      <ContentLayout>
        <PageTitle>Toolbox</PageTitle>
        <ToolSearchBar
          shownTools={shownTools}
          setShownTools={setShownTools}
          setSearch={setSearch}
          search={search}
          selectedLabels={selectedLabels}
        />
        <ToolLabels
          selectedLabels={selectedLabels}
          setSelectedLabels={setSelectedLabels}
          setShownTools={setShownTools}
        />
        {shownTools.length > 0 ? (
          <Tools>
            <>
              {shownTools.map((tool) => (
                <Tool key={tool.id} tool={tool} />
              ))}
            </>
          </Tools>
        ) : (
          <div className="flex flex-col gap-4">
            <p className="dark:text-primary-dark text-primary-light text-2xl font-semibold text-center">
              No tools available with the selected filters
            </p>
            <p className="dark:text-primary-dark text-primary-light text-xl font-medium text-center">
              Please try adjusting the search parameters.
            </p>
          </div>
        )}
      </ContentLayout>
    </MainLayout>
  );
}
