import HeaderMenuButton from "../ui/HeaderMenuButton";
import PropTypes from "prop-types";
import ToolMenu from "./ToolMenu";
import tools from "../../data/tools.json";
import { useState } from "react";

export default function ToolsSelector({ id }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="md:w-auto relative w-1/2">
      <HeaderMenuButton setIsOpen={setIsOpen} image="tool" width="w-full">
        Tools
      </HeaderMenuButton>

      <ToolMenu tools={tools} id={id} open={isOpen} />
    </div>
  );
}

ToolsSelector.propTypes = {
  id: PropTypes.number,
};
