import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import PageHeader from "../components/ui/PageHeader";
import ToolsSelector from "../components/toolbox/ToolSelector";
import { useState } from "react";

function reverseComplement(dna) {
  const seq = dna.toUpperCase();

  if (!seq || seq.length === 0) {
    return "Invalid DNA sequence.";
  }

  const complements = {
    A: "T",
    T: "A",
    G: "C",
    C: "G",
    N: "N",
  };

  return seq
    .split("")
    .reverse()
    .map((base) => complements[base])
    .join("");
}

export default function ReverseComplement() {
  const [sequence, setSequence] = useState("");
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  const handleSequenceChange = (e) => {
    setResult(null);
    setError(null);
    setSequence(e.target.value);
  };

  const handleReverseComplement = () => {
    const cleanedSequence = sequence.replace(/\s+/g, "").toUpperCase();

    if (/[^ACGTN]/.test(cleanedSequence)) {
      setError(
        "Invalid DNA sequence. Sequence must contain only A, C, G, T, and N."
      );
      return;
    }

    const reversedComplement = reverseComplement(sequence);
    if (reversedComplement === "Invalid DNA sequence.") {
      setError("Invalid DNA sequence.");
    } else {
      setResult(reversedComplement);
    }
  };

  return (
    <MainLayout>
      <PageHeader to="/tools" title="Reverse Complement">
        <ToolsSelector id={19} />
      </PageHeader>
      <ContentLayout>
        {/* Input Sequence Section */}
        <section className="space-y-2">
          <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            1. Insert your DNA Sequence
          </label>
          <textarea
            value={sequence}
            onChange={handleSequenceChange}
            rows="5"
            className={`w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 ${
              error ? "border-2 !border-red-500" : ""
            }`}
            placeholder="e.g. ACGTTACGTCGTACGTANN"
          />
          {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
        </section>

        {/* Reverse Complement Button */}
        <button
          onClick={handleReverseComplement}
          className="border-light dark:border-dark bg-primary-btn-light enabled:hover:bg-primary-btn-hover dark:bg-primary-btn-dark enabled:dark:hover:bg-primary-btn-hoverDark text-primary-dark w-full px-4 py-2 pr-6 mb-4 text-lg font-semibold text-center duration-200 rounded-lg">
          Get Reverse Complement
        </button>

        {/* Result Section */}
        {result && !error && (
          <section className="mt-4 space-y-2">
            <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
              Reverse Complement
            </label>
            <textarea
              readOnly
              value={result}
              rows="5"
              className="w-full p-2 rounded-lg bg-white dark:bg-gray-800 text-primary-light dark:text-primary-dark border-light dark:border-dark outline-none focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200"
            />
          </section>
        )}
      </ContentLayout>
    </MainLayout>
  );
}
