import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import PageHeader from "../components/ui/PageHeader";
import ToolsSelector from "../components/toolbox/ToolSelector";
import { useState } from "react";
import { validateFastaSequence } from "../helpers/validation";

export default function NSpacers() {
  const [inputSequences, setInputSequences] = useState("");
  const [spacerLength, setSpacerLength] = useState("");
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  const handleSequenceChange = (e) => {
    setResult(null);
    setError(null);
    setInputSequences(e.target.value.toUpperCase());
  };

  const handleSpacerLengthChange = (e) => {
    setResult(null);
    setError(null);
    setSpacerLength(e.target.value);
  };

  const calculateNSpacers = () => {
    const cleaned = inputSequences.toUpperCase().replace(/[^A-Z,]/g, "");
    const seqArray = cleaned.split(",").filter((seq) => seq !== "");

    if (seqArray.length < 2) {
      setError("Please enter at least 2 sequences separated by commas.");
      return;
    }

    seqArray.forEach((seq) => {
      if (!validateFastaSequence(seq)) {
        setError(
          "Sequences must be either all DNA (ACGT) or all Protein (ACDEFGHIKLMNPQRSTVWY)."
        );
      }
      return;
    });

    const spacerLen = parseInt(spacerLength, 10);
    if (isNaN(spacerLen) || spacerLen < 0 || spacerLen > 50) {
      setError("Spacer length must be an integer between 0 and 50.");
      return;
    }

    const spacerStr = "N".repeat(spacerLen);
    const output = seqArray.join(spacerStr);
    setResult(output);
  };

  return (
    <MainLayout>
      <PageHeader to="/tools" title="Add N Spacers">
        <ToolsSelector id={17} />
      </PageHeader>
      <ContentLayout>
        <section className="space-y-2">
          <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            1. Insert your sequences (separated by commas)
          </label>
          <textarea
            value={inputSequences}
            onChange={handleSequenceChange}
            rows="5"
            className={`w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 ${
              error ? "border-2 border-red-500" : ""
            }`}
            placeholder="e.g. ATGCGT,CGTACG,TTGACA,GGGCTA"
          />
        </section>

        <section className="mt-4 space-y-2">
          <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            2. Spacer Length (0 to 50)
          </label>
          <input
            type="number"
            value={spacerLength}
            onChange={handleSpacerLengthChange}
            className={`w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 ${
              error ? "border-2 border-red-500" : ""
            }`}
            placeholder="Enter spacer length"
          />
        </section>

        <button
          onClick={calculateNSpacers}
          className="border-light dark:border-dark bg-primary-btn-light enabled:hover:bg-primary-btn-hover dark:bg-primary-btn-dark enabled:dark:hover:bg-primary-btn-hoverDark text-primary-dark w-full px-4 py-2 pr-6 mb-4 text-lg font-semibold text-center duration-200 rounded-lg">
          Add N Spacers
        </button>

        {error && (
          <section className="mt-4">
            <div className="bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark p-4 border-2 border-red-500 rounded-lg shadow-lg">
              <h3 className="text-lg font-semibold text-center">Error</h3>
              <p className="text-center">{error}</p>
            </div>
          </section>
        )}

        {result && !error && (
          <section className="mt-4 space-y-2">
            <label className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
              Output Sequence
            </label>
            <textarea
              readOnly
              value={result}
              rows="5"
              className="w-full p-2 rounded-lg bg-white dark:bg-gray-800 text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none focus:!border-blue-500 placeholder-gray-500 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200"
            />
          </section>
        )}
      </ContentLayout>
    </MainLayout>
  );
}
